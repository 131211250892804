import React, { useState, useEffect } from "react"
import classNames from "classnames"
import { Helmet } from "react-helmet"

// example src prop
// https://secureform.seamlessdocs.com/f/f0502156ae49efe8f6208cb42986f460?embedded=true

const Seamless = ({ src, className }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    // Load Seamless resizer JS when mounted
    setMounted(true)
    return () => {
      setMounted(false)
    }
  }, [])

  const iframeClasses = classNames("seamlessdocsEmbededIframe", className)

  return (
    <>
      {mounted && (
        <Helmet>
          <script>
            {`window.seamlessVariables = {url:"https://secureform.seamlessdocs.com"};`}
          </script>
          <script
            type="text/javascript"
            src="https://secureform.seamlessdocs.com/components/odoForms/js/embed-iframe/head.js"></script>
          <script
            defer
            type="text/javascript"
            src="https://secureform.seamlessdocs.com/components/odoForms/js/embed-iframe/body.js"></script>
        </Helmet>
      )}
      <iframe
        className={iframeClasses}
        src={src}
        frameBorder="0"
        height="700px"
        width="100%"
        allowtransparency="true"
        sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation allow-popups-to-escape-sandbox"></iframe>
    </>
  )
}

export default Seamless
